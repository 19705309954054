import React, { useState, useCallback, useEffect, useMemo } from 'react';

import './index.scss';

const Content = () => {
  const adFunction = () => {
    const script = document.createElement('script');
    script.src = '//t1.daumcdn.net/kas/static/ba.min.js';
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  };
  // 마운트시 애드핏 cdn 추가, 서브 이동시마다 업뎃
  useEffect(() => {
    adFunction();
  }, []);

  return (
    <section className="sc-content">
      <h3>백신 종류 현황</h3>
      <div className="table-scroll-wrap">
        <table>
          <caption className="hide">백신 종류 현황</caption>
          <thead>
            <tr>
              <th scope="col" rowspan="2">
                구분
              </th>
              <th scope="col" colspan="4">
                개발사
              </th>
            </tr>
            <tr>
              <th scope="col">화이자</th>
              <th scope="col">모더나</th>
              <th scope="col">아스트라제네카</th>
              <th scope="col">얀센</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>플랫폼</td>
              <td>mRNA백신</td>
              <td>mRNA백신</td>
              <td>바이러스벡터백신</td>
              <td>바이러스벡터백신</td>
            </tr>
            <tr>
              <td>연령</td>
              <td>만 16세 이상</td>
              <td>만 18세 이상</td>
              <td>만 18세 이상</td>
              <td>만 18세 이상</td>
            </tr>
            <tr>
              <td>구성</td>
              <td>다회 투여용 바이알</td>
              <td>다회 투여용 바이알</td>
              <td>다회 투여용 바이알</td>
              <td>다회 투여용 바이알</td>
            </tr>
            <tr>
              <td>접종</td>
              <td>1000만명</td>
              <td>2000만명</td>
              <td>1000만명</td>
              <td>600만명</td>
            </tr>
            <tr>
              <td>접종횟수</td>
              <td>2회</td>
              <td>2회</td>
              <td>2회</td>
              <td>1회</td>
            </tr>
            <tr>
              <td>접종간격</td>
              <td>21일</td>
              <td>28일</td>
              <td>4~12주</td>
            </tr>
            <tr>
              <td>접종량</td>
              <td>희석된 백신 0.3㎖</td>
              <td>0.5㎖</td>
              <td>0.5㎖</td>
              <td>0.5㎖</td>
            </tr>
            <tr>
              <td>방법</td>
              <td>근육주사</td>
              <td>근육주사</td>
              <td>근육주사</td>
              <td>근육주사</td>
            </tr>
            <tr>
              <td>보관</td>
              <td>-90℃ ~ -60℃ (6개월)</td>
              <td>-25℃∼-15℃ (7개월)</td>
              <td>2~8℃ (6개월)</td>
              <td>-25℃∼-15℃ (24개월)</td>
            </tr>
            <tr>
              <td>유통</td>
              <td>
                -90℃ ~ -60℃(6개월)
                <br />
                2~8℃ (5일)
              </td>
              <td>2~8℃ (30일)</td>
              <td>2~8℃ (6개월)</td>
              <td>2~8℃ (3개월)</td>
            </tr>
            <tr>
              <td>개봉 후 저장</td>
              <td>
                희석 후 실온
                <br />
                (2℃∼30℃) 6시간
              </td>
              <td>실온 6시간</td>
              <td>실온(30℃이하) 6시간</td>
              <td>냉장에서 4∼6시간</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* 컨텐츠 중간 1 */}
      <div className="ad-area">
        <ins
          class="kakao_ad_area"
          style={{ display: 'none' }}
          data-ad-unit="DAN-4NLJkzhmGGtN8ZLk"
          data-ad-width="320"
          data-ad-height="100"
        ></ins>
        <script
          type="text/javascript"
          src="//t1.daumcdn.net/kas/static/ba.min.js"
          async
        ></script>
      </div>
      <h3>접종 계획</h3>
      <a href="https://ncv.kdca.go.kr/menu.es?mid=a10117010000" target="_blank">
        분기 접종계획 안내
      </a>
      <div className="table-scroll-wrap">
        <table>
          <caption class="hide">일정</caption>
          <colgroup>
            <col />
            <col />
            <col />
          </colgroup>
          <thead>
            <tr>
              <th scope="col">시기</th>
              <th scope="col">인원</th>
              <th scope="col">대상</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1분기</td>
              <td>130만</td>
              <td>
                1. 요양병원, 요양시설 입원, 입소자, 종사자
                <br />
                2. 코로나19 환자를 치료하는 기관 종사자
                <br />
                3. 고위험 의료기관종사자(보건의료인)
                <br />
                4. 정신요양, 재활시설등 입소자, 종사자
                <br />
                5. 1차 대응요원 (역학조사, 구급대 등)
              </td>
            </tr>
            <tr>
              <td>2분기</td>
              <td>900만</td>
              <td>
                1. 노인재가복지시설 이용자, 종사자
                <br />
                2.65세 이상 (고령자부터 순차 접종)
                <br />
                3. 의료기관 및 약국 종사자(보건의료인) (1분기 접종대상 外)
                <br />
                4. 장애인·노숙인 등 시설 입소자, 종사자
              </td>
            </tr>
            <tr>
              <td>3분기</td>
              <td rowSpan="2">3,325만</td>
              <td>
                1. 성인 만성질환자
                <br />
                2. 성인 50∼64세
                <br />
                3. 군인, 경찰, 소방 및 사회 기반시설 종사자
                <br />
                4. 소아, 청소년 교육, 보육시설 종사자
                <br />
                5. 성인 18∼49세
              </td>
            </tr>
            <tr>
              <td>4분기</td>
              <td>1. 2차 접종자,미접종자 또는 재접종자 (항체유지기간 고려)</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="cont">
        <article>
          <h3>코로나바이러스감염증-19 백신 종류</h3>
          <p>
            전 세계 여러 나라에서 mRNA나 DNA를 이용한 백신, 바이러스 벡터 백신
            및 불활성화 백신, 재조합 백신, 바이러스 유사입자 백신 등 여러 종류의
            백신을 개발 중에 있습니다. 우리나라는 mRNA백신(화이자·모더나社),
            바이러스 벡터(아스트라제네카·얀센社) 백신,
            합성항원백신(노바백스社)을 도입할 예정입니다.
          </p>
        </article>
        <article>
          <h3>코로나19 예방접종과 예방원리</h3>
          <p>
            코로나19 백신 물질이 우리 몸에 들어와 면역세포인 B세포와 T세포를
            자극합니다. B세포는 코로나19에 대항하는 항체를 만들고, 면역세포 중
            일부는 기억세포로 남습니다. 이후 우리 몸에 코로나바이러스-19가
            침투했을 때 예방접종을 통해 만들어진 항체와 기억 세포가 바이러스
            침입에 빠르게 반응하여 코로나바이러스-19 감염증을 예방합니다.
          </p>
        </article>
        <article>
          <h3>코로나19 예방접종은 누가 받아야 하나요?</h3>
          <p>
            전 국민이 코로나19 예방접종 대상입니다. 단, 백신 개발과정에서
            임상시험에 포함되지 않은 임신부와 18세 미만 소아청소년은
            접종대상에서 제외되나, 추가적 임상결과에 따라 변경될 수 있습니다.
          </p>
        </article>

        <article>
          <h3>코로나19 예방접종은 누가 받아야 하나요?</h3>
          <p>65세 이상 노인들 접종이 완료된 후 3분기 이후 가능할 것입니다.</p>
        </article>
        <article>
          <h3>예방접종 후 나타날 수 있는 이상반응은?</h3>
          <ul>
            <li>접종부위 통증, 부기, 발적</li>
            <li>발열, 메스꺼움, 근육통</li>
            <li>피로감</li>
            <li>두통</li>
          </ul>
          <p>
            일반적으로 예방접종 후 흔하게 나타나는 국소 이상반응은 접종부위
            통증이나 부기, 발적 등이 있으며, 전신반응으로 발열, 피로감, 두통,
            근육통, 메스꺼움ㆍ구토 등이 나타날 수 있습니다. 접종 후 흔히
            나타나는 반응으로 별다른 조치 없이 대부분 3일 이내 증상이
            사라집니다. <br />
            <br />
            이는 정상적인 면역형성과정에서 나타날 수 있습니다. 다만, 39℃ 이상의
            고열, 알레르기 반응(두드러기나 발진, 얼굴이나 손 부기) 등의 증상이
            나타나거나, 일반적으로 나타나는 이상반응의 증상이 일상생활을
            방해하는 정도라면 의료기관을 방문하여 진료를 받으시기 바랍니다.
          </p>
        </article>

        {/* 컨텐츠 중간 2 */}
        <div className="ad-area">
          <ins
            class="kakao_ad_area"
            style={{ display: 'none' }}
            data-ad-unit="DAN-OEFvXeuMRq7zFTq8"
            data-ad-width="320"
            data-ad-height="100"
          ></ins>
          <script
            type="text/javascript"
            src="//t1.daumcdn.net/kas/static/ba.min.js"
            async
          ></script>
        </div>
        <article>
          <h3>
            코로나19 예방접종 후 이상반응 발생 시, 증상 완화를 위하여 어떻게
            조치하면 될까요?
          </h3>
          <ul>
            <li>접종부위 통증이 있는 경우 깨끗한 수건으로 냉찜질</li>
            <li>미열이 있는 경우 수분을 충분히 섭취후 휴식</li>
            <li>전신 통증이 있는 경우 진통제가 도움이 될수 있음</li>
            <li>증상이 심해질 경우 의료기관 방문하기</li>
          </ul>
          <p>
            접종 후 접종부위 부기·통증이 있는 경우, 깨끗한 마른 수건을 대고 그
            위에 냉찜질을 하세요. 접종 후 미열이 있는 경우, 수분을 충분히
            섭취하고 휴식을 취하세요. 접종 후 발열, 근육통 등으로 불편할 경우,
            해열·진통제를 복용하시면 도움이 됩니다. * 예방접종 전에 미리
            아세트아미노펜 성분의 해열·진통제를 준비하고, 예방 접종 후 몸살
            증상이 있으면 해열·진통제를 복용하세요.
          </p>
        </article>
        <article>
          <h3>아스트라제네카 코로나19 백신의 30세 미만 접종이 제외된 이유?</h3>
          <p>
            코로나19 예방접종대응추진단은 예방접종전문위원회의 권고사항을 토대로
            아스트라제네카 코로나19 백신의 30세 미만 접종을 제외하기로 했습니다.
            이는 유럽의약품청(EMA)과 영국 의약품건강관리제품규제청(MHRA)이
            희귀혈전증을 아스트라제네카 백신 접종의 부작용으로 분류한 것을
            반영한 과학적 분석결과(연령별 접종 위험-이득 분석)에 근거한 것으로,
            30세 미만의 경우 백신접종으로 유발될 수 있는 희귀혈전증으로 인한
            위험에 비해 백신접종으로 인한 이득이 크지 않은 것으로 판단되었기
            때문입니다. 영국도 같은 분석방식으로 분석한 결과를 토대로,
            기저질환이 없는 30세 미만에서는 다른 백신의 접종을 권고하고
            있습니다.
          </p>
        </article>
      </div>

      <div className="cont">
        <article>
          <h3>우리나라에는 어떤 백신이 도입되나요?</h3>
          <li>
            모든 국민이 접종 완료할 수 있도록 총 9천 9백만 명분에 대해 백신을
            확보하였습니다.
          </li>
          <li>
            국내 도입하는 백신은 바이러스 벡터 백신 2종(아스트라제네카 코로나19
            백신, 얀센 코로나19 백신), mRNA 백신 2종(화이자 코로나19 백신,
            모더나 코로나19 백신), 재조합 백신 1종(노바백스 코로나19 백신)에
            대해 구매 계약을 체결하였습니다.
          </li>
          <li>
            국제백신공급기구(COVAX) 1천만명 분, 아스트라제네카 1천만명 분,
            화이자 3천3백만명 분, 얀센 6백만명 분, 모더나 2천만명 분, 노바백스
            2천만명 분입니다.
          </li>
          <li>
            현재 우리나라에서는 아스트라제네카 코로나19
            백신(한국아스트라제네카코비드-19백신주, 2월 10일) 화이자 코로나19
            백신(코미나티주, 3월5일), 얀센 코로나19 백신(코비드-19백신 얀센주,
            4월7일), 모더나 코로나19 백신(모더나코비드-19백신주, 5월21일)이 허가
            되었습니다.{' '}
            <a href="www.mfds.go.kr/vaccine_covid19.jsp" target="_blank">
              링크
            </a>
          </li>

          <ul></ul>
        </article>
        <article>
          <h3>각 백신별 특성과 용법은 어떤가요?</h3>
          <ul>
            <li>
              화이자 코로나19 백신은 보관온도가 –90℃∼-60℃로 보관을 위해
              초저온냉동고가 필요하며, 초저온냉동고가 구비되어 있는
              예방접종센터에서 접종하게 됩니다. 또한, 화이자 코로나19 백신은
              접종 전 희석 전처리 과정이 필요하며, 21일 간격으로 2회 접종
              합니다.
            </li>
            <li>
              아스트라제네카 코로나19 백신은 2℃∼8℃에서 냉장보관 할 수 있습니다.
              식품의약품안전처 허가 사항은 4주~12주 간격으로 2회 접종이나, 접종
              간격이 넓을수록 예방효과 및 면역반응이 증가하는 경향을 나타내어
              8주~12주로 접종을 권고합니다.
            </li>
            <li>
              모더나 코로나19 백신은 28일 간격으로 2회 접종하고, 얀센 코로나19
              백신은 1회 접종합니다.
            </li>
            <li>코로나19 예방접종은 위팔의 삼각근 부위에 근육주사 합니다.</li>
          </ul>
        </article>
        <article>
          <h3>5~6월에는 누가 코로나19 예방접종을 하나요?</h3>
          <p>
            5월부터는 60세 이상 대상으로 접종이 시작됩니다. 75세 이상 어르신
            우선 4월 1일부터 예방접종 센터를 통해 접종이 시작되었으며, 60-74세
            연령은 5월 6일부터 연령별 순차 접종 예약이 진행 중입니다. 이와 함께
            만성중증호흡기질환자 및 유치원‧어린이집, 초등(1‧2학년) 교사 및
            돌봄인력을 대상으로 예방접종을 시행하며 60-74세 연령과 함께 해당
            대상자는 5월 27일부터 위탁의료기관을 통해 접종을 시행합니다. 6월 중,
            아스트라제네카 접종연령 변경에 따라 접종하지 못한 2분기 30세 미만
            접종대상자 접종이 시행됩니다.
          </p>
        </article>
        <article>
          <h3> 저는 언제 예방접종을 받을 수 있나요?</h3>
          <p>
            18~59세의 건강한 성인의 경우 3분기 경 예방접종을 시작할 계획입니다.{' '}
            <br />* (’21 하반기) 접종순서를 고려하되, 백신 도입물량 확대 등을
            감안하여 효과적인 전국민 접종 방법 검토‧추진
          </p>
        </article>
        <article>
          <h3>
            먼저 코로나 예방접종을 시행한 국가에서 나타난 예방접종 후 이상반응은
            무엇이 있나요?
          </h3>
          <p>
            최근까지 각 국가에서 보고되는 코로나19 예방접종의 일반적인
            이상반응으로는 접종부위의 통증 및 발적, 두통, 피로감이나 발진 등
            피부증상 등이 있었으며, 대부분 접종 후 1~2일 이내에 발생하여 며칠
            이내 사라졌다고 보고하고 있습니다.
          </p>
          <p>
            다만 주요 중증 이상반응으로 아나필락시스가 나타나며, 영국에서는
            아나필락시스와 아나필락시스양 반응을 모두 포함하여 보고하였으며,
            화이자 접종 후 인구 백만명당 22.8건(약 1,080만 접종 중 246건),
            아스트라제네카 접종 후 인구 백만명당 24.7건(약 1,580만 접종 중
            390건) 발생을 보고하고 있습니다. <br />
            <br />
            프랑스에서는 화이자 접종 후 인구 백만명당 36.1건 (약 481만 접종 중
            174명), 모더나 접종 후 인구 백만명당 2.1건 (약 47만 접종 중 1명),
            아스트라제네카 접종 후 인구 백만명당 2.6건(약 192만 접종 중 5명)
            발생을 보고하고 있습니다.
          </p>
        </article>

        <a
          href="https://ncv.kdca.go.kr/menu.es?mid=a10119000000"
          target="_blank"
        >
          [질병관리청] 코로나19 예방접종
        </a>
        <br />
        <a
          href="https://ncv.kdca.go.kr/menu.es?mid=a10118030000"
          target="_blank"
        >
          [질병관리청] 국외 코로나19 예방접종 후 이상반응 보고 현황
        </a>
        <br />

        <a
          href="https://factchecker.or.kr/hot_issues?keyword=%EC%BD%94%EB%A1%9C%EB%82%9819"
          target="_blank"
        >
          [팩트체크넷] 코로나19 전문, 시민 팩트체크
        </a>
      </div>
      {/* 컨텐츠 하단 */}
      <div className="ad-area">
        <ins
          class="kakao_ad_area"
          style={{ display: 'none' }}
          data-ad-unit="DAN-0fwegtkm35HAb0p6"
          data-ad-width="320"
          data-ad-height="100"
        ></ins>
        <script
          type="text/javascript"
          src="//t1.daumcdn.net/kas/static/ba.min.js"
          async
        ></script>
      </div>
    </section>
  );
};

export default Content;
